.franchise>div{
    max-width: 1170px;
    margin: auto;
    text-align: center;
    padding: 20px 0;
}

.franchise-head-heading-1{
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.franchise-head-heading-2{
    font-family: 'Times New Roman', Times, serif;
    font-size: 2.3rem;
    font-weight: 500;
    color: #d3af37;
}
.franchise-head-icon img{
    width: 150px;
    margin-top: -10px;
    
}
.franchise-head-text{
    padding: 10px 10px 30px;
}
    

.franchise-step{
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px 10px;
}
.franchise-step>div{
    /* max-width: 1170px; */
    margin: auto;
}

.franchise-step-heading-1{
    font-size: 1.3rem;
}

.franchise-step-heading-2{
    font-size: 2.5rem;
    font-family: 'Times New Roman', Times, serif;
    color: #d3af37;
}

.franchise-step-icon>img{
    width: 150px;
    margin-top: -5px;
}


/* --------------- */

.franchise-step-content{
    display: flex;
    gap: 30px;
    justify-content: center;
    padding: 40px 20px;
}

.franchise-step-content-col{
    width: 300px;
    box-shadow: 6px -3px 12px rgba(255, 255, 255, 0.588);
}
    
.franchise-step-content-col-heading{
    background-color: #726d6d;
    font-size: 1.3rem;
    font-family: 'Times New Roman', Times, serif;
    padding: 50px 30px;
}
.franchise-step-content-col-heading>span{
    font-family: 'Times New Roman', Times, serif;
    font-size: 2rem;
}

.franchise-step-content-col-text{
    background-color: #d3af37;
    color: black;
    padding: 20px;
}


/* ------------------------------- */

.franchise-contact{
    max-width: 1170px;
    margin: auto;
    padding: 50px 0;
}

.franchise-contact-heading{
    font-size: 2.5rem;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;

}

.franchise-contact-text{
    margin: 20px 10px 40px;
    
}
    
.franchise-contact-form form{
    /* width: 100%; */
    display: grid;
    gap: 20px;
}
.franchise-contact-form form div{
    display: flex;
    gap: 20px;
}
.franchise-contact-form form select,
.franchise-contact-form form input{
    padding: 8px;
    font-size: 1rem;
    border: solid 1px rgb(208, 208, 208);
}
.franchise-contact-form form div>select,
.franchise-contact-form form div>input{
    width: 100%;
}
.franchise-contact-form input{
    width: auto;
}
.franchise-contact-form button{
    width: 200px;
    margin: auto;
    padding: 8px;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    background: #f1c01e;
    color: black;
    margin-top: 10px;
}

/* -------------------------------------------------- */

.franchise-contact-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 0;
    gap: 30px;
}

.franchise-contact-content-col-1{
    /* border: solid rgb(131, 131, 131); */
    padding: 15px;
    border-radius: 20px;
    background-color: black;
}
.franchise-contact-content-col-1>iframe{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}
.franchise-contact-content-col-2{
    padding: 20px 0;
}
.franchise-contact-content-col-2-heading{
    font-size: 1.8rem;
    font-weight: 600;
    /* font-family: 'Times New Roman', Times, serif; */
    margin-bottom: 10px;
}

.franchise-contact-content-col-2-details{
    display: grid;
    gap: 20px;
}

.franchise-contact-content-col-2-point-heading{
    font-size: 1.1rem;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {

    
.franchise-head-heading-1{
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.franchise-head-heading-2{
    font-size: 1.8rem;
}
.franchise-head-icon img{
    width: 100px;
    margin-top: -5px;
    
}
.franchise-head-text{
    padding: 10px 10px 20px;
    font-size: .9rem;
}
    
    /* -------------------- */

    
.franchise-step-heading-1{
    font-size: 1.2rem;
}

.franchise-step-heading-2{
    font-size: 1.7rem;
}

.franchise-step-heading-3{
    font-size: .9rem;
}

.franchise-step-icon>img{
    width: 100px;
    margin-top: -5px;
}

.franchise-step-content{
    overflow: auto;
    justify-content: space-between;
    padding: 30px 20px 10px;
}


.franchise-step-content-col{
    min-width: 280px;
}


.franchise-step-content-col-heading{
    font-size: 1.2rem;
    padding: 30px 20px;
}
.franchise-step-content-col-heading>span{
    font-size: 1.8rem;
}

.franchise-step-content-col-text{
    font-size: .9rem;
}
    

/* ------------------------- */


.franchise-contact{
    padding: 50px 20px;
}

.franchise-contact-heading{
    font-size: 1.8rem;
}

.franchise-contact-text{
    margin: 10px 10px 30px;
    font-size: .9rem;
 
}
    
.franchise-contact-form form{
    gap: 15px;
}

.franchise-contact-form form div{
    display: grid;
    gap: 15px;
}

.franchise-contact-form form div>select,
.franchise-contact-form form div>input{
    width: auto;
}
.franchise-contact-form input{
    width: auto;
}

    

/* -------------------------------------------------- */

.franchise-contact-content{
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 0 0;
    gap: 10px;
}

.franchise-contact-content-col-1{
    border: solid rgb(131, 131, 131);
    border: none;
    padding: 12px;
    border-radius: 20px;
    background-color: black;
    height: 250px;
}

.franchise-contact-content-col-2{
    padding: 0px 0;
}
.franchise-contact-content-col-2-heading{
    font-size: 1.5rem;
    font-weight: 600;
    /* font-family: 'Times New Roman', Times, serif; */
    margin-bottom: 10px;
}

.franchise-contact-content-col-2-details{
    display: grid;
    gap: 10px;
}

.franchise-contact-content-col-2-point-heading{
    font-size: 1.1rem;
    font-weight: 600;
}




}


    