
.admin-home-heading{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}

.admin-home-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.admin-home-list>div>a{
    display: flex;
    font-family: poppins;
    align-items: center;
    gap: 20px;
    padding: 10px;
    text-decoration: none;
    font-size: 1.7rem;
    color: black;
    font-weight: 500;
    
}
.admin-home-list>div>a:hover{
    color: #d3af37;

}
.admin-home-list>div>a>i{
    color: rgb(255, 255, 255);
    border-radius: 50%;
    width:  60px;
    height: 60px;
    background-color: black;
    
    box-shadow: rgba(255, 255, 255, 0.404) 0px 0px 10px;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
.admin-home-list>div>a{
    font-size: 1.3rem;
}

    .admin-home-list>div>a>i{
        width: 40px;
        height: 40px;
        font-size: 1.2rem;
    }    
    
}

