.error-page{
    background-image: url(../../media/images/Full-Body-Wax-For-Men.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px;
}

.error-page>div{
    max-width: 1170px;
    margin: auto;
    text-align: center;
}
.error-page-heading{
    font-size: 4rem;
    font-weight: 600;
}
.error-page-heading-1{
    font-size: 3rem;
    font-weight: 700;
}
.error-page-heading-2{
    font-size: 2rem;
    font-weight: 600;
}
.error-page-heading-3{
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 50px;
}
.error-page-heading-3 a{
    color: #d3af37;
}
.error-page-text{
    margin-bottom: 50px;
}
.error-page-button a{
    font-size: 1.2rem;
    background-color: #d3af37;
    padding: 10px 50px;
    text-decoration: none;
    color: black;
    font-weight: 500;
}
.error-page-button a:hover{
    background-color: #886802;
    color: white;
}


@media only screen and (max-width: 600px) {

    .error-page{
        padding: 80px 15px;
    }
    
    .error-page-heading{
        font-size: 2.5rem;
    }
    
.error-page-heading-1{
    font-size: 2rem;
    font-weight: 700;
}
.error-page-heading-2{
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 10px 0;
}
.error-page-heading-3{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 3form0px;
}
    .error-page-button a{
        font-size: 1rem;
        padding: 8px 40px;
    }
    
    
}


