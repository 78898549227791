.pagination{
    text-align: center;
}
.pagination>div{
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: flex-end;
}
.pagination-buttons>a{
    text-decoration: none;
    color: black;
    cursor: pointer;
    /* font-size: 1.2rem; */
    border: solid 1px rgb(179, 179, 179);
    border-radius: 50%;
    width:  35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #bf925b; */
}

.pagination-buttons>a:hover{
    background-color: #bf925b;
    /* color: white; */
    
}

.pagination-button-active{
    color: white !important;
    background-color: #bf925b;
}

