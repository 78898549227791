.blog-page {
  background-color: white;
  padding: 40px;
}

.blog-page > div {
  max-width: 1170px;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 80px;
}

.blog-page-col-1 {
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.blog-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.blog-section-img>img{
  width: 100%;

}


.blog-section-head {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.blog-section-content {
  padding: 10px 20px 30px;
}
.blog-section-content-heading {
  font-size: 1.1rem;
  font-weight: 600;
}

.blog-section-content-text {
  font-size: 0.9rem;
  padding: 10px 0 20px;
}

.blog-section-content-button > a {
  padding: 8px 15px;
  background-color: #bf925b;
  color: white;
  text-decoration: none;
}
.blog-section-content-button > a:hover {
  border: #bf925b solid;
  background-color: white;
  color: #bf925b;
  font-weight: 500;
}

/* ------------------------------ */

.blog-page-col-2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.blog-page-col-2 > div {
  background-color: black;
  color: white;
  padding: 30px 20px 10px;
}
.blog-page-col-2-glazma-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: 400;
}

.blog-page-col-2-heading {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.blog-page-col-2-content {
  display: grid;
  gap: 25px;
}
.blog-page-col-2-content-list {
  text-decoration: none;
  color: white;
}
.blog-page-col-2-content-heading {
  font-size: 1.15rem;
}
.blog-page-col-2-content-list:hover {
  font-weight: 600;
  margin-left: 10px;
  margin-right: -15px;
  color: #bf925b;
}

.blog-page-col-2-content-date {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  color: #bf925b;
}

.blog-page-col-2-content-show {
  padding: 25px 0 0px;
  color: #e1ad6c;
  text-align: end;
  font-size: 1.1rem;
  text-decoration: underline;
}
.blog-page-col-2-content-show > span:hover {
  color: white;
  font-size: 1.2rem;
}

/* -------------------------------------------------- */


.blog-component-col-2-pagination{
  display: flex;
  justify-content: center;
}
.blog-component-col-2-pagination-buttons{
  display: flex;
  gap: 10px;
}

.blog-component-col-2-pagination-btn{
  border: #bf925b solid 1px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.blog-component-col-2-pagination-btn-active{
  background-color: #bf925b;
  color: white;
}
.blog-component-col-2-pagination-btn-disable{
  background-color: #f5eee5;
  color: #bf925b;
  border: none;
  cursor: no-drop;
}   





@media only screen and (max-width: 600px) {
  .blog-page {
    padding: 30px 15px;
  }

  .blog-page > div {
    grid-template-columns: 1fr;
  }

  .blog-section {
    grid-template-columns: 1fr;
  }

  .blog-page-col-1 {
    gap: 80px;
  }

  .blog-section-head {
    padding: 10px 0px 0;
  }

  .blog-section-content {
    padding: 10px 0px;
  }

  /* ---------------------- */
  .blog-page-col-2 {
    /* display: none; */
  }

  /* ----------------------------------------------- */
    
.blog-component-col-2-pagination{  
}
.blog-component-col-2-pagination-buttons{
  gap: 7px;
}

.blog-component-col-2-pagination-btn{
  font-size: .9rem;
  width:  30px;
  height: 30px;
}
  
  
  
}
