.privacy-policy{
    padding: 40px 10px;
}
.privacy-policy>div{
    max-width: 1170px;
    margin: auto;
    display: grid;
    gap: 30px;
    text-align: center;
}

.privacy-policy-col-text a{
    text-decoration: none;
    color: black;
    font-weight: 500;
}
.privacy-policy-col-heading{
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 2px;
}

@media only screen and (max-width: 600px) {

.privacy-policy>div{
    gap: 20px;
}
    
.privacy-policy-col-text{
    font-size: .9rem;
}
.privacy-policy-col-heading{
    font-size: 1.1rem;
}

}