.increment{
    background-image: url(../../media/images/Full-Body-Wax-For-Men.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.increment-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1170px;
    margin: auto;
    text-align: center;
    padding: 50px 0 50px;
}


.increment-list-col-head>span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    color: #d3af37;
    font-weight: 600;
    text-align: center;
    margin: auto;
}

.increment-list-col-head img{
    width: 50px;
}

@media only screen and (max-width: 600px) {
.increment-list{
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 10px;
    gap: 30px 10px;
}


.increment-list-col-head img{
    width: 30px;
}

.increment-list-col-head>span{
    font-size: 1.5rem;
}

.increment-list-col-text{
    font-size: .8rem;
}

}




