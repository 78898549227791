.single-blog{
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    margin-top: -30px;
}

.single-blog-heading{
    font-size: 2rem;
    margin-top: 30px;
    font-weight: 500;
}

.single-blog-heading-1{
    font-size: 1.4rem;
    margin-top: 20px;
    font-weight: 500;
}

.single-blog-text{
    line-height: 1.8rem;
}

.single-blog-text-1{
    font-size: .9rem;
    line-height: 1.5rem;
}
.single-blog-img>img{
    margin-top: 20px;
    width: 100%;
}
.single-blog-points{
    text-align: start;
    padding-left: 30px;
}


@media only screen and (max-width: 600px) {
    
.single-blog-heading{
    font-size: 1.6rem;
    margin-top: 30px;
}

.single-blog-text{
    line-height: 1.6rem;
    /* font-size: .95rem; */
}


.single-blog-heading-1{
    font-size: 1.2rem;
}



}


