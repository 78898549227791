.about-head {
  background-color: white;
  padding: 40px;
}
.about-head > div {
  max-width: 1170px;
  margin: auto;
}

.about-head-heading-1 {
  text-align: center;
  font-size: 2.2rem;
  font-family: "Times New Roman", Times, serif;
  color: #d3af37;
  font-weight: 600;
}

.about-head-heading-2 {
  text-align: center;
  margin-top: 10px;
}

.about-head-icon {
  text-align: center;
  margin: -5px;
  margin-bottom: 10px;
}

.about-head-icon > img {
  width: 150px;
}

/* --------------------------------- */
.about-head-col {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
  padding: 20px 0;
}
.about-head-col-1 > img {
  width: 100%;
  border: solid 5px rgb(222, 222, 222);
}

.about-head-col-2-heading {
  font-size: 2.2rem;
  font-weight: 600;
  color: #d3af37;
  font-family: "Times New Roman", Times, serif;
}

.about-head-heading-3 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 30px;
}

/* -------------------------- */

.about-beliefs {
  background-color: black;
  color: white;
  padding: 40px;
}
.about-beliefs > div {
  max-width: 1170px;
  margin: auto;
}

.about-beliefs-heading-1 {
  text-align: center;
  font-size: 2.2rem;
  font-family: "Times New Roman", Times, serif;
  color: #d3af37;
  font-weight: 600;
}

.about-beliefs-text {
  text-align: center;
  font-size: 0.9rem;
}

.about-beliefs-icon {
  text-align: center;
}

.about-beliefs-icon > img {
  width: 150px;
  margin-top: -10px;
}

.about-beliefs-content {
  display: grid;
  gap: 40px;
  margin-top: 60px;
}
.about-beliefs-content > div {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.about-beliefs-content-col {
  display: flex;
  gap: 20px;
  align-items: center;
}
.about-beliefs-content-col-icon {
  font-size: 2rem;
  color: #d3af37;
}

.about-beliefs-content-col-heading {
  font-size: 1.6rem;
}

/* ---------------------------- */

.about-purpose {
  background-color: white;
  padding: 40px;
}
.about-purpose > div {
  max-width: 1170px;
  margin: auto;
}

.about-purpose-heading {
  font-size: 2.2rem;
  font-weight: 600;
  color: #d3af37;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  margin-top: 40px;
}
.about-purpose-icon {
  text-align: center;
}
.about-purpose-icon > img {
  margin-top: -10px;
  width: 150px;
}
.about-purpose-img {
  text-align: center;
  padding: 20px 0;
}
.about-purpose-img > img {
  max-width: 100%;
}

/* ----------------------- */
.about-section-2 {
  background-color: black;
  color: white;
  padding: 50px;
}
.about-section-2 > div {
  max-width: 1170px;
  margin: auto;
}
.about-section-2-heading {
  font-family: "Times New Roman", Times, serif;
  font-size: 2.2rem;
  font-weight: 600;
  text-align: center;
  margin: 20px;
}

/* ---------------------------------- */

.about-section-3{
    background-color: white;
    padding: 40px;
}
.about-section-3>div{
    max-width: 1170px;
    margin: auto;
}

.about-section-3-heading-1{
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.8rem;
    text-align: center;
}
.about-section-3-heading-2{
    font-family: 'Times New Roman', Times, serif;
    font-size: 2.2rem;
    text-align: center;
    color: #d3af37;
    font-weight: 600;
}

.about-section-3-icon{
    text-align: center;
}
.about-section-3-icon>img{
    width: 150px;
}

.about-section-3-col{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    padding: 20px;
    gap: 50px;
}

.about-section-3-col-point-icon>img{
    width: 100px;
}

.about-section-3-col-point-heading{
    font-size: 1.3rem;
    font-weight: 500;
}

/* ----------------------------- */

.about-section-4{
  background-color: black;
  color: white;
  padding: 40px;
}
.about-section-4>div{
  max-width: 1170px;
  margin: auto;
}

.about-section-4-heading{
  font-size: 2.2rem;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  color: #d3af37;
  font-weight: 600;
}
.about-section-4-col{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px 0;
}
.about-section-4-col>div{
  background-color: white;
  color: black;
  /* padding: 20px; */
}

.about-section-4-col-point-content{
  padding: 10px 20px 20px;
}
.about-section-4-col-point-image>img{
  width: 100%;
}

.about-section-4-col-point-heading{
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
}

/* ---------------------------------- */

.about-section-5-increment{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  gap: 10px;
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {
  .about-head {
    padding: 30px 15px;
  }

  .about-head-heading-1 {
    font-size: 1.5rem;
  }

  .about-head-heading-2 {
    font-size: 0.9rem;
  }

  .about-head-icon {
    margin: -5px;
    margin-bottom: 10px;
  }

  .about-head-icon > img {
    width: 100px;
  }

  .about-head-text {
    font-size: 0.9rem;
  }

  /* --------------------------------- */
  .about-head-col {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px 0;
  }
  .about-head-col-1 {
    width: 80%;
    margin: auto;
  }

  .about-head-col-2-heading {
    font-size: 1.5rem;
  }

  .about-head-heading-3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 20px;
  }

  /* -------------------------- */

  /* -------------------------- */

  .about-beliefs {
    padding: 30px 15px;
  }

  .about-beliefs-heading-1 {
    font-size: 1.5rem;
  }

  .about-beliefs-text {
    font-size: 0.85rem;
  }

  .about-beliefs-icon > img {
    width: 100px;
    margin-top: -5px;
  }

  .about-beliefs-content {
    gap: 40px;
    margin-top: 30px;
  }

  .about-beliefs-content > div {
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .about-beliefs-content-col {
    gap: 15px;
  }

  .about-beliefs-content-col-icon {
    font-size: 1.6rem;
  }

  .about-beliefs-content-col-heading {
    font-size: 1.3rem;
  }

  .about-beliefs-content-col-text {
    font-size: 0.8rem;
  }

  /* ---------------------------- */

  .about-purpose {
    padding: 30px 15px;
  }

  .about-purpose-heading {
    font-size: 1.5rem;
    margin-top: 30px;
  }

  .about-purpose-icon > img {
    margin-top: -5px;
    width: 100px;
  }

  .about-purpose-text {
    font-size: 0.9rem;
  }

  .about-purpose-img {
    text-align: center;
    padding: 5px 0;
  }

  /* ----------------------- */
  .about-section-2 {
    padding: 30px 15px;
  }
  .about-section-2-heading {
    font-size: 1.5rem;
    margin: 10px;
  }
  .about-section-2-text {
    font-size: 0.9rem;
    text-align: justify;
  }
  /* -------------- */
  
.about-section-3{
  background-color: white;
  padding: 30px 15px;
}


.about-section-3-heading-1{
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.about-section-3-heading-2{
  font-size: 1.5rem;
}
.about-section-3-text{
  font-size: 0.9rem;
}

.about-section-3-col-point-heading{
  font-size: 1.1rem;
}


.about-section-3-col{
    display: grid;
    grid-template-columns: 1fr ;
    text-align: start;
    padding: 20px 0;
    gap: 30px;
}

.about-section-3-col-point{
  display: flex;
  gap: 10px;
  
}


.about-section-3-col-point-icon>img{
  width: 60px;
}


/* ----------------------------- */

.about-section-4{
  padding: 30px 0px ;
}

.about-section-4-heading{
  font-size: 1.5rem;
  padding: 0 15px;
}

.about-section-4-col{
  display: flex;
  overflow: auto;
  gap: 20px;
  padding: 15px;
}

.about-section-4-col-point-content{
  padding: 10px ;
}
.about-section-4-col-point-heading{
  font-size: 1.1rem;
  margin-bottom: 0px;
  min-width: 250px;
}
.about-section-4-col-point-text{
  font-size: .9rem;
}




}
