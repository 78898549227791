

.slick-arrow{
    display: none !important;
}


.header-slider{
    height: 550px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    justify-content: center;
    color: white;
    /* padding: 110px 0; */
    cursor: grab;
    
}

.header-slider-heading-1{
    font-size: 3.3rem;
    font-weight: 500;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.header-slider-heading-1 .location-page-location{
    color: #d3af37;
}

.header-slider-heading-2{
    font-size: 1.2rem;
    position: relative;
    font-weight: normal;
}

.header-slider-heading-2:after {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    content: "";
    font-family: Nunito;
    height: 2px;
    position: absolute;
    width: 100px;
    margin-top: 15px;
    margin-left: 20px;   
}

.header-slider-heading-2:before{
    background: rgb(255, 255, 255);
    border-radius: 10px;
    content: "";
    font-family: Nunito;
    height: 2px;
    position: absolute;
    width: 100px;
    margin-top: 15px;
    margin-left: -120px;
}

.slick-dots {
    display: none !important;
}

.header-slider-1{
    background: url(../../media/images/Male-Private-Part-Hair-Removal-Salon-Near-Me.jpg);
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
}


.header-slider-2{
    background: url(../../media/images/Pubic-Hair-Removal.jpg);
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
}


.header-slider-3{
    background: url(../../media/images/Manzilian-Wax-Near-Me.jpg);
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
}


.header-slider-4{
    background: url(../../media/images/Full-Body-Wax-Male-Price-1.jpg);
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
}



/* ------------------ header-2 start ------------------ */

.header-2>div{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-width: 1170px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: -40px;
    background-color: white;
}

.header-2-col-img{
    background-image: url(../../media/images/Men-Waxing-Near-Me.jpg);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
}


.header-2-col{
    display: flex;
    padding: 30px 30px 30px 80px;
    background-color: white;
}

.header-2-col-icon>img{
    width: 50px;
}

.header-2-col-content{
    padding:  20px;
}
.header-2-col-content-heading{
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    margin-bottom: 5px;
    font-weight: 600;
    gap: 5px;
}
.header-2-col-content-text{
    font-size: .9rem;
    margin-bottom: 10px;
}

.header-2-col-content-text a{
    text-decoration: none;
    color: blue;
}

.header-2-col-content-button{
    display: flex;
    justify-content: center;
}

.header-2-col-content-button>a{
    border: solid #d3af37 2px;
    font-weight: 600;
    padding: 2px 10px;
    text-decoration: none;
    color: black;
}
.header-2-col-content-button>a:hover{
    background-color: #d3af37;
    color: white;
}

/* ------------------ header-2 end ------------------ */

/* ------------------ why manscape start ------------------ */

.why-manscape{
    background-color: black;
    color: white;
    text-align: center;
    padding: 50px 0;
}
.why-manscape>div{
    max-width: 1170px;
    margin: auto;
}

.why-manscape-heading-1{
    font-size: 1.6rem;
    font-weight: 500;
}
.why-manscape-heading-2{
    font-size: 2rem;
    color: #d3af37;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
}
.why-manscape-heading-3>img{
    width: 150px;
    margin: 10px auto;
}

.why-manscape-points{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.why-manscape-points-list-icon>img{
    height: 60px;
    border-bottom: #d3af37 solid 5px;
    padding: 0 25px 10px;
    padding-top: 0;
    
}

.why-manscape-points-list-heading{
    font-size: 1.8rem;
}
.why-manscape-points-list-text{
    font-size: .86rem;
    margin-top: 5px;
}

.why-manscape-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 70px;
}

.why-manscape-container-col{
    background-color: #1d1d1d;
    padding: 40px 10px;
}

.why-manscape-container-heading-1{
    font-size: 1.6rem;
}

.why-manscape-container-heading-2{
    font-size: 2.2rem;
    color: #d3af37;
    font-weight: 500;
}

.why-manscape-container-icon>img{
    width: 140px;
}

.why-manscape-container-img>img{
    border: #d3af37 solid 6px;
    margin-top: 30px;
}

/* ------------------ why manscape end ------------------ */


@media only screen and (max-width: 600px) {

    .header-slider>img{
        height: 250px;
        object-fit: cover;
    }
    
    
    .header-slider{
        height: 250px;
        padding: 10px;
        justify-content: center;

}

.header-slider-heading-1{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
}
.header-slider-heading-2{
    font-size: .8rem;
    position: relative;
}

.header-slider-heading-2:after {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    content: "";
    font-family: Nunito;
    height: 1.5px;
    position: absolute;
    width: 30px;
    margin-top: 10px;
    margin-left: 5px;   
}

.header-slider-heading-2:before{
    background: rgb(255, 255, 255);
    border-radius: 10px;
    content: "";
    font-family: Nunito;
    height: 1.5px;
    position: absolute;
    width: 30px;
    margin-top: 9px;
    margin-left: -35px;
}

    




/* ------------------ header-2 start ------------------ */

.header-2{
    padding: 0 10px;
}
.header-2>div{
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0px;
}
    
.header-2-col-icon>img{
    width: 50px;
}

.header-2-col-img{
    height: 200px;
}

.header-2-col{
    padding: 20px 20px 20px 5px;
}
/* ------------------ header-2 end ------------------ */

    

/* ------------------ why manscape start ------------------ */

.why-manscape{
    background-color: black;
    color: white;
    text-align: center;
    padding: 30px 10px;
}


.why-manscape-heading-1{
    display: none;
}

.why-manscape-heading-2{
    font-size: 1.5rem;
}
.why-manscape-heading-3>img{
    width: 100px;
    margin-top: -1px;
}

.why-manscape-points{
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}

.why-manscape-points{
    padding: 0 20px;
}

.why-manscape-points-list{
    /* border: #d3af37 solid; */
}
.why-manscape-points-list-head{
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-manscape-points-list-icon>img{
    height: 30px;
    padding: 0 8px 3px;
    border-bottom: #d3af37 solid 3px;
}

.why-manscape-points-list-heading{
    font-size: 1.1rem;
    font-weight: 600;
}

.why-manscape-points-list-text{
    margin: 0;
    text-align: start;
    text-align: center;
}

.why-manscape-container{
    grid-template-columns: 1fr ;
    margin: 20px 0;
}


.why-manscape-container-col{
    padding: 10px 20px;
}

.why-manscape-container-heading-1{
    font-size: 1.3rem;
}

.why-manscape-container-heading-2{
    font-size: 1.6rem;
}


.why-manscape-container-icon>img{
    width: 100px;
}


.why-manscape-container-img>img{
    width: 95%;
    border: #b79731 solid 5px;
    margin-top: 10px;
}



}







