.faq{
    background-color: black;
    color: white;
    padding: 40px 0;
}
.faq>div{
    max-width: 1170px;
    margin: auto;
}
.faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    /* text-align: center; */
    font-size: 2.2rem;
    font-weight: 600;
    color: #d3af37;
    background-color: black;
    font-family: 'Times New Roman', Times, serif;
    position: sticky;
    top: -1px;
}
.faq-icon{
    text-align: center;
}
.faq-icon>img{
    width: 150px;
}
    
.faq-content{
    display: grid;
    gap: 50px;
    padding: 80px 0 20px;
}

.faq-content-list{
    display: grid;
    gap: 10px;
}

.faq-content-ques{
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    font-size: 1.2rem;
}

.faq-content-ans span{
    font-weight: 600;
}
.faq-content-ans{
    font-size: .9rem;
}


@media only screen and (max-width: 600px) {

.faq{
    padding: 15px 10px;
}
    
    .faq-heading{
        font-size: 1.3rem;
        padding: 10px 0;
    }

.faq-content{
    padding: 20px;
    gap: 30px;
}
    
    
}