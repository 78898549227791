.cart-fix-col{
  position: fixed;
  right: 15px;
  bottom: 35px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  z-index: 1;
}

.cart-fix-col-text{
  cursor: pointer;
  background-color: #d5d5d5;
  background-color: #000000;
  color: #fff;
  box-shadow: rgba(211, 211, 211, 0.568) 0px 0px 7px 0px;
  /* border: solid 1px #f00; */

  /* margin-top: -5px; */
  padding: 10px;
  font-weight: 500;
  font-size: 1.3rem;
  margin-top: 10px;
  margin-right: -20px;
  padding: 10px 40px 10px 20px;
  font-family: poppins
  /* background-color: #25d366; */
  /* display: flex; */
  
}
.cart-fix-col-text span{
  font-weight: 600;
}

.cart-fix-button {
  z-index: 1;
  cursor: pointer;
  /* position: fixed; */
  right: 15px;
  bottom: 55px;
}
.cart-fix-button div {
  color: white;
  background-color: #25d366;
  box-shadow: 4px 4px 6px 0 hsla(0, 0%, 100%, 0.222),
    -4px -4px 6px 0 hsla(213, 8%, 49%, 0.297),
    inset -4px -4px 6px 0 hsla(0, 0%, 100%, 0.088), inset 4px 4px 6px 0 #0006;
  border-radius: 50%;
  height: 35px;
  width:  35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3px;
  letter-spacing: -2px;
  font-size: 1.2rem;
  font-weight: 700;
  margin-left: 45px;
  margin-bottom: -25px;
  position: relative;
  z-index: 1;
}

.cart-content-main-content{
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding-top: 50px;
}

.cart-fix-button i {
  height: 80px;
  width:  80px;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  background: #fe0000;
  box-shadow: 
  /* 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #747d8880, */
    inset -4px -4px 6px 1px hsla(0, 0%, 100%, 0.227),
    inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412);
}

.cart-content {
  position: fixed;
  z-index: 3;
  top: 0;
  background-color: rgba(61, 61, 61, 0.74);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.cart-content-close{
  /* display: flex; */
  width: 90%;
  /* justify-content: flex-end; */
  text-align: right ;
  color: #fe0000;
  z-index: 1;
  font-size: 2rem;
  /* font-weight: 1000; */
  margin-bottom: -40px;
}

.cart-content-main {
  background-color: white;
  width: 60%;
  height: 90%;
  font-family: "poppins";
  position: relative;
}
 
.cart-content-heading {
  text-align: center;
  /* margin: 20px; */
  font-family: "poppins";
  font-weight: 600;
  font-size: 1.2rem;
  background-color: black;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-content-heading i{
  font-size: 2rem;
}

.cart-content-list {
  /* margin: 20px; */
  height: 85%;
  padding-bottom: 40px;
  overflow: auto;
}

.cart-content-list-col {
  display: grid;
  grid-template-columns: 9fr 1fr;
  margin: 30px 25px;
  align-items: center;
  gap: 5px;
}
.cart-content-list-price{
  font-size: 1.2rem;
}
/* ------------------------------------------------------------------ */

.cart-content-list-service{
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.cart-content-list-service-buttons{
  display: flex;
  align-items: center;
  gap: 30px;
}
.cart-content-list-service-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: black;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 29px;
}
.cart-content-list-service-qun{
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px rgb(197, 197, 197);
  width: 80px;
  height: 35px;
  font-size: 1.3rem;
  font-weight: 600;
}

.cart-content-total-content{
  display: flex;
  gap: 10px;
  font-size: 1.2rem;
}

.cart-content-total-price{
  font-weight: 700;
  /* font-size: 1rem; */
}


/* ---------------------------------------------------------------- */

.cart-content-list-service-name {
  font-weight: 500;
  font-size: 1rem;
}

.cart-content-list-service-price {
  font-weight: 700;
}

.cart-content-total-main {
  /* padding: 10px 0; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fe0000;
  background-color: #fff;
}
.cart-content-total {
  /* margin: 20px; */
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-weight: 600; */
  background-color: rgb(203, 203, 203);
  /* color: white; */
  padding: 2px 20px;
}

.cart-content-checkout-disabled,
.cart-content-checkout {
  /* margin: auto; */
  text-align: center;
  width: 150px;
  background-color: black;
  color: white;
  padding: 5px;
  font-weight: 500;
  border-radius: 50px;
}
.cart-content-checkout-disabled{
  background-color: #646464;
  cursor: no-drop;
}
.cart-content-total-value{
  padding: 0 20px 10px ;
}

.cart-content-total-value-heading{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-weight: 600;
}


.cart-content-total-value-bar{
  background: #e4e4e4;
  border-radius: 20px;
  margin: 5px 0;
}
.cart-content-total-value-bar>div{
  background-color: #25d366;
  height: 10px;
  border-radius: 20px;
}




@media only screen and (max-width: 600px) {

.cart-fix-col{
  bottom: 20px;
  right: 10px;
  width: 93%;

}


  .cart-content-main-content{
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding-top: 50px;
  }  
  
  
.cart-content-main {
  background-color: white;
  width: 100%;
  height: 90%;
  font-family: "poppins";
}
.cart-fix-col-text{
  font-size: 1rem;
  flex: 1;
  width: 100%;
  text-align: center;
}

.cart-fix-button i {
  height: 50px;
  width:  50px;
  font-size: 1.3rem;
}

.cart-fix-button div {
  height: 20px;
  width:  20px;
  font-size: 1rem;
  margin-left: 30px;
  margin-bottom: -15px;
  
}


/* ------------------------------------------------------------------ */


.cart-content-list-service-name {
  font-size: .9rem;
  line-height: 1.3rem;
}

/* ----- */


.cart-content-list-service{
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.cart-content-list-service-buttons{
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart-content-list-service-btn{
  font-size: 1.1rem;
  width:  22px;
  height: 22px;
}
.cart-content-list-service-qun{
  width: 30px;
  height: 29px;
  font-size: 1.1rem;
  font-weight: 600;
}

/* ---- */


.cart-content-total {
  flex-direction: column;
  /* gap: 5px; */
}
.cart-content-total>
.cart-content-total-content:first-child{
  display: none;
}
.cart-content-total-content{
  display: flex;
  gap: 10px;
}



/* ---------------------------------------------------------------- */
.cart-content-total-value{
  padding: 0 10px 10px;
  font-size: .9rem;
}

.cart-content-total-value-text{
  font-size: .8rem;

}


.cart-content-checkout-disabled,
.cart-content-checkout {
    padding: 2px 25px;
}


.cart-content-list-col {
  margin: 20px 10px;
}

  
}

