.loading-component{
    position: fixed;
    z-index: 8;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    background-color: rgba(255, 255, 255, 0.792);
    /* color: white; */
    font-weight: 700;
}
/* .loading-component */