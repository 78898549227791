

/* --------------- popup offer ----------------- */

.popup-offer-main {
  position: fixed;
  z-index: 4;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.534);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-offer-content {
  position: relative;
  background-color: white;
  background: url("../../media/images/Full-Body-Wax-Near-Me.png");
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 10px;
  padding-top: 20px;
  margin: 10px;
  width: 550px;
  /* height: 420px; */
  
  box-shadow: rgba(255, 255, 255, 0.73) 0px 7px 29px 0px;
  
  display: flex;
  flex-direction: column;
}

.popup-offer-head{
  text-align: center;
}

.popup-offer-head-heading{
  /* animation: flipX 6s ease infinite; */
  font-size: 2.3rem;
  font-weight: 600;
  text-shadow: #000000 0px 0 2px;
  color: #d3af37;
}
.popup-offer-head-text{
  font-size: 1.2rem;
  padding: 0 80px;
}

.popup-offer-content-div {
  padding: 40px 30px;
  margin-bottom: -25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.popup-offer-content-close-btn {
  position: absolute;
  right: -10px;
  top: -20px;
  /* text-align: end; */
  font-size: 2.5rem;
  z-index: 2;
  /* color: black; */
  color: #d3af37;
  /* margin-top: -10px; */
  /* margin-bottom: -10px;
  margin-right: -20px; */
}
.popup-offer-content-close-btn i{
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
}

.popup-offer-content-heading-1 {
  font-size: 1.7rem;
  line-height: 1rem;
  font-weight: 500;
}

.popup-offer-content-heading-2 {
  font-weight: 700;
  font-size: 3rem;
  color: #d3af37;
}
.popup-offer-content-heading-3 {
  font-size: 1.3rem;
  font-weight: 500;
}

.popup-offer-content form {
  padding: 10px 0;
  display: grid;
  gap: 10px;
  width: 350px;
}
.popup-offer-content form input {
  padding: 5px 10px;
  font-size: 1.1rem;
  border-radius: 5px;
  border: 0;
}
.popup-offer-content form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.popup-offer-content form button {
  background-color: #d3af37;
  color: rgb(0, 0, 0);
  padding: 4px 40px;
  border-radius: 10px;
  border: none;
  width: 200px;
  box-shadow: 0px 0px 2px 0 #ff9a9a80, -0px -0px 2px 0 #d9989880,
    inset -1px -4px 6px 0 #fff3, inset 4px 4px 6px 2px #0006;
  font-size: 1.2rem;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .popup-offer-content {
    max-width: 400px;
    height: auto;
    padding-top: 10px;
  }

  .popup-offer-content-close-btn{
    font-size: 2.2rem;
  }

.popup-offer-head-heading{
  font-size: 1.9rem;
}

.popup-offer-head-text{
  font-size: 1.1rem;
  padding: 0 20px;
}
  
  
  .popup-offer-content-div {
    padding: 30px 10px;
    margin-bottom: -20px;
  }

  .popup-offer-content form {
    width: 250px;
  }

  .popup-offer-content-heading-1 {
    font-size: 1.5rem;
  }

  .popup-offer-content-heading-2 {
    font-size: 2.3rem;
  }

  .popup-offer-content-heading-3 {
    font-size: 1rem;
  }

  .popup-offer-content form input {
    padding: 4px 10px;
    font-size: 0.9rem;
  }
  .popup-offer-content form button {
    padding: 3px 20px;
    border-radius: 10px;
    width: 130px;
    font-size: 1rem;
  }

  
  
}
