.service-comp{
    background-color: white;
    padding: 40px;
    /* border: #e4ba32 solid 5px; */
}
.service-comp>div{
    max-width: 1170px;
    margin: auto;
}

.service-comp-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-size: 2.2rem;
    font-weight: 600;
    color: #e4ba32;
    font-family: 'Times New Roman', Times, serif;
}

.service-comp-list{
    font-size: .9rem;
    margin-top: 10px;
}


@media only screen and (max-width: 600px) {

    .service-comp{
        padding: 15px 10px;
    }

.service-comp-heading{
    font-size: 1.3rem;
}
}