
/* ------------------ footer ----------------- */

.footer-logo img{
    width: 250px;
}



.landing-footer {
  /* ------------------- bg img ------------------  */
  background-position: center;
  background-size: cover;
  background-color: black;
  background: url(../../media/images/Full-Body-Wax-Male-Price.jpg);
  background-position:  center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 50px 0 0; 
}
  
.landing-footer>div{
  max-width: 1350px;
  margin: auto;
}
  .landing-footer svg {
    transform: rotate(180deg);
    fill: white;
    margin-top: -2px;
  }
  
  .landing-footer-cols {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0px 50px 40px 50px;
    gap: 50px;
  }
  
  .landing-footer-col-1 {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    /* justify-content: space-evenly; */
    align-items: center;
    /* border-right: solid gray; */
    padding-right: 50px;
  }
  
  .landing-footer-col-1-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-family: playpen sans;
    font-weight: 800;
    color: #ce0000;
    gap: 5px;
    text-decoration: none;
  }
  
  .landing-footer-col-1-logo span {
    color: rgb(255, 255, 255);
  }
  
  .landing-footer-logo-icon img {
    width: 6rem;
    display: flex;
    align-items: center;
  }
  
  .landing-footer-logo-text-2 {
    font-family: poppins;
    font-size: 1.1rem;
    color: white;
    font-weight: 400;
  }
  
  .landing-footer-col-1-footer-icon {
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: space-between;
    width: 90%;
    gap: 20px;
  }
  
  .landing-footer-col-1-footer-icon  i {
    color: white;
    color: rgb(162, 162, 162);
    border: solid;
    border-radius: 50%;
    padding: 10px;
    font-size: 1.2rem;
  }
  .landing-footer-col-1-footer-icon > a > i:hover{
    color: #d3af37;
  }
  
  .landing-footer-col-2 {
    display: flex;
    justify-content: space-around;
  }
  
  .landing-footer-col-2-col-heading {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 14px;
    position: relative;
  }
  
  .landing-footer-col-2-col-heading:before {
    content: "";
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: 50px;
    height: 3px;
    background-color: white;
  }
  
  .landing-footer-col-2-col-points {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    color: rgb(195, 195, 195);
  }
  
  .landing-footer-col-2-col-points > div,
  .landing-footer-col-2-col-points > a {
    display: flex;
    gap: 15px;
    align-items: center;
    color: rgb(195, 195, 195);
    text-decoration: none;
  }
  
  .landing-footer-col-2-col-points > a:hover{
    color: #d3af37;
    text-decoration: underline;
  }

    
  .landing-footer-cc {
    text-align: center;
    padding: 20px;
    color: rgb(182, 182, 182);
    /* margin-top: 40px; */
    border-top: solid 1px gray;
  }

  @media only screen and (max-width: 600px) {

    
    
.landing-footer {
    background: url(../../media/images/Full-Body-Wax-Male-Price-1.jpg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
  padding: 30px 0 0 ;
}
    
  .landing-footer-cols {
    grid-template-columns: 1fr;
    gap: 40px;
  }
    
  .landing-footer-cols {
    padding: 10px;
  }
  
  .landing-footer-col-1 {
    padding: 0;
    gap: 10px;
  }
  
  .landing-footer-col-2 {
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;

    gap: 40px 10px;
  }
  
  .landing-footer-col-2-col-heading{
    font-size: 1.1rem;
  }

  .landing-footer-cc {
    font-size: .9rem;
  }

  .landing-footer-col-1-footer-icon{
    gap: 10px;
    justify-content: space-evenly;
  }
  .landing-footer-col-1-footer-icon  i {
    font-size: 1rem;
  }
    
  }