.hidden-icon{
    margin-top: -1px;
    width: 1px;
    display: flex;
}
.hidden-icon>img{
    width: 100%;
}
/* ------------------------ */
.home-package{
    text-align: center;
    padding: 50px 0;
}
.home-package>div{
    max-width: 1170px;
    margin: auto;   
}
.home-package-list{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.home-package-heading-1{
    color: #d3af37;
    font-size: 2.2rem;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
}

.home-package-head-icon>img{
    width: 150px;
}

.home-package-head-text>a{
    color: blue;
    text-decoration: none;
}

.home-package-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 10px;
    padding: 60px 20px;
}
.home-package-wedding-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 30px;
    padding: 60px 20px;
}

.home-package-icon>img{
    width: 80px;
}
.home-package-heading{
    font-weight: 600;
}
.home-package-text{
    font-size: .9rem;
}
.home-package-price{
    color: red;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
}
.home-package-btn>span{
    cursor: pointer;
    background-color: #dbb127;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: 500;
}
.home-package-btn>span:hover{
    background-color: #ffdd6c;
    color: black;
}

.home-package-button span{
    border: #d3af37 solid;
    padding: 5px 10px;
    font-weight: 500;
}
.home-package-button span:hover{
    background-color: #d3af37;
    color: white;
}


/* ---------------- DayToDayComponent start -------------- */
.day-2-day{
    background-color: black;
    color: white;
    text-align: center;
    padding: 50px;
}
.day-2-day>div{
    max-width: 1170px;
    margin: auto;
}

.day-2-day-heading{
    font-size: 2.1rem;
    font-weight: 600;
    color: #d3af37;
    font-family: 'Times New Roman', Times, serif;
}
.day-2-day-icon>img{
    width: 150px;
}

.day-2-day-button a{
    color: white;
    text-decoration: none;
    border: #d3af37 solid;
    padding: 5px 10px;
    font-weight: 500;
}
.day-2-day-button a:hover{
    background-color: #d3af37;
}

.day-2-day-content{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin: 20px 0 50px;
}

.day-2-day-content-list{
    background-color: #252525;
}

.day-2-day-content-list-heading{
    color: #d3af37;
    padding: 10px;
    font-size: 1.1rem;
    font-weight: 500;
}
.day-2-day-content-list-image>img{
    width: 100%;
}

.day-2-day-content-list-text{
    font-size: .9rem;
    text-align: start;
    padding: 0 10px 20px;
}
.day-2-day-content-list-text>a{
    color: red;
    text-decoration: none;
}

/* ---------------- DayToDayComponent end -------------- */



@media only screen and (max-width: 600px) {


    .home-package{
        text-align: center;
        padding: 30px 10px;
    }
    
    .home-package-head-icon>img{
        width: 100px;
    }
.home-package-heading-1{
    color: #d3af37;
    font-size: 1.5rem;
    font-weight: 600;
}

.home-package-head-text{
    display: none;
}

.home-package-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 10px;
    padding: 10px 0px 30px;
}


.home-package-wedding-content{
    grid-template-columns: 1fr;
    gap: 30px 10px;
    /* padding: 60px 20px; */
    padding: 20px 20px 30px;
}
.home-package-icon>img{
    width: 70px;
}
.home-package-heading{
    font-size: .9rem;
}

.home-package-text{
    font-size: .85rem;
}

.home-package-price{
    color: red;
    font-size: 1.1rem;
}






/* ---------------- DayToDayComponent start -------------- */
.day-2-day{
    padding: 30px 0px;
}


.day-2-day-heading{
    font-size: 1.4rem;
    font-weight: 500;
    color: #d3af37;
    padding: 0 20px;
}

.day-2-day-icon>img{
    width: 100px;
}
.day-2-day-content{
    overflow: auto;
    padding: 0 20px;
}

.day-2-day-content-list-heading{
    min-width: 230px;
    font-size: 1rem;
}

}