/* ---------------- top-button ----------------- */

.top-button{
    position: fixed;
    left: 15px;
    bottom: 25px;
}

.top-button>button{
    background-color: rgb(0, 0, 0);
    color: white;
    width:  50px;
    height: 50px;
    border: solid rgb(70, 70, 70) 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}



/* -------------- contact buttons ----------------- */

.contact-fix-buttons{
    position: fixed;
    right: 15px;
    top: 43%;
    display: grid;
    gap: 20px;
}
.contact-fix-buttons>a{
    /* background-color: black; */
    color: white;    
    width:  70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 30px;
}

.contact-fix-button-call{
    background: #fe0000;
    
    box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #747d8880, inset -4px -4px 6px 1px hsla(0,0%,100%,.227), inset 5px 4px 6px 2px rgba(0,0,0,.412);
}
.contact-fix-button-call>span{
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: play 1.5s ease infinite;
}
.contact-fix-button-call>span>i{
    animation: shake 1.5s ease infinite;
}
.contact-fix-button-wa{
    background-color: #25d366;
    box-shadow: 4px 4px 6px 0 hsla(0,0%,100%,.222), -4px -4px 6px 0 hsla(213,8%,49%,.297), inset -4px -4px 6px 0 hsla(0,0%,100%,.088), inset 4px 4px 6px 0 #0006;
}
.contact-fix-button-wa i{
    font-size: 35px;
}
.contact-fix-button-wa::before{
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: pulse-border;
    border: 14px solid #25d366;
    border-radius: 50%;
    content: "";
    position: absolute;
}

/* .contact-fix-buttons{} */


/* ---------------- offer ------------------ */





@media only screen and (max-width: 600px) {

    .top-button{
        position: fixed;
        right: 15px;
        bottom: 20px;
    }
    
    .top-button>button{
        width:  40px;
        height: 40px;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none !important;
    }
    
    .contact-fix-buttons>a{
            
        width:  50px;
        height: 50px;
    font-size: 20px;

        
    }
.contact-fix-button-wa::before{
    border: 4px solid #25d366;
}
.contact-fix-button-wa i{
    font-size: 23px;
}
}
