    .admin-data-header{
        display: flex;
        justify-content: space-between;
        /* justify-content: space-around; */
        position: sticky;
        top: 0;
        background-color: #ffffff;
        box-shadow: 2px 4px 10px rgba(169, 169, 169, 0.696);
        align-items: center;
        padding:  5px 150px;

    }

    .admin-data-heading{
        font-size: 2rem;
        text-transform: capitalize;
        font-weight: 600;
    }

    .admin-data-filter i{
        font-size: 1rem;
    }
    .admin-data-filter{
        /* position: relative; */
        padding: 5px 30px;
        font-size: 1.2rem;
        /* border: solid 1px rgb(47, 47, 47); */
        box-shadow: 1px 1px 6px rgba(145, 145, 145, 0.936);
        border-radius: 20px;
    }
    .admin-data-filter-col{
        /* background-color: rgb(31, 31, 31); */
        box-shadow: 2px 4px 10px rgba(169, 169, 169, 0.696);
background-color: white;
        font-size: 1rem;
        padding: 10px;
        /* width: 200px; */
        flex-direction: column;
        gap: 10px;
        display: flex;
        
    }
    .admin-data-filter-col{
        position: absolute;
        /* right: 0; */
    }

    /* ---------------------------------------------------- */

    .admin-data-list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 20px;
        gap: 50px 30px;
    }

    .admin-data-list-col{
        /* background-color: black; */
        box-shadow: 2px 4px 10px rgb(169, 169, 169);

        /* box-shadow: 2px 4px 10px #fff3; */
        padding: 10px;
    }


    .admin-data-list-col-header{
        text-align: center;
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 3px 0;
    }

    .admin-data-list-status-red{
        background-color: red;
        color: white;
    }
    .admin-data-list-status-green{
        background-color: green;
        color: white;
    }
    .admin-data-list-status-yellow{
        background-color: rgb(255, 111, 0);
        color: white;
    }

    .admin-data-list-col-data{
        display: grid;
        grid-template-columns: 2fr 3fr;
        padding: 5px 15px ;
    }

    .admin-data-list-col-data-heading{
        font-size: 1.1rem;
        font-weight: 600;
    }

    .admin-data-list-col-bold{
        font-weight: 600;
        border: 2px solid rgb(172, 172, 172);
        padding-top: 0 !important;
        margin-top: 12px;
    }
    .admin-data-list-col-service-list{
        display: grid;
        grid-template-columns: 1fr 9fr 3fr 1fr 3fr;
        gap: 5px;
        padding-top: 10px;
        text-align: center;
    }
    .admin-data-list-col-service-list-data{
        height: 120px;
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
        overflow-y: auto;

    }
    .admin-data-list-col-service-list-total{
        display: flex;
        gap: 10px;
        justify-content: space-between;
        border: 2px solid rgb(172, 172, 172);
        padding: 2px 0;
        margin-top: 10px;
    }


    .admin-data-list-col-button{
        /* background-color: red; */
        text-align: center;
        width: 100px;
        /* padding: 5px; */
        border-radius: 20px;
        margin: 20px auto 10px;
        font-size: 1.1rem;
        font-weight: 600;
    }



    @media only screen and (max-width: 600px) {
    .admin-data-header{
        padding: 8px 30px;
    }
    .admin-data-heading{
        font-size: 1.3rem;
    }

    .admin-data-filter{
        padding: 3px 5px 3px 15px;
        font-size: 1rem;
        text-align: center;
    }
    .admin-data-filter span{
        display: none;
    }

    .admin-data-filter-col{
        right: 0;
        font-size: .9rem;
    }

    .admin-data-list{
        grid-template-columns: 1fr;
        /* padding: 10px 20px; */
    }

    .admin-data-list-col-data-heading{
        font-size: .9rem;
    }

    .admin-data-list-col-text{
        font-size: .9rem;
        color: rgb(0, 0, 0);
    }

    .admin-data-list-col-header{
        font-size: 1rem;
    }

    .admin-data-list-col-service-list-total,
    .admin-data-list-col-service-list{
        font-size: .9rem;
    }

    

    .admin-data-list-col-button{
        /* background-color: red; */
        text-align: center;
        width: 100px;
        /* padding: 5px; */
        border-radius: 20px;
        margin: 20px auto 10px;
        font-size: 1.1rem;
        font-weight: 600;
    }

    
    }

