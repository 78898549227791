.view-cart {
  /* background: #000; */
  background: #0009;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 3;
}

.view-cart-close {
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  position: absolute;
}

.view-cart-main {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  position: relative;
  width: max-content;
  width: 500px;
  margin-left: 30px;
}

.view-cart-top {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 1.2rem;
}

.view-cart-top > span {
  font-size: 1rem;
  cursor: pointer;
}

.view-cart-content {
  padding: 0 20px 200px;
  overflow: auto;
  height: calc(100vh - 110px);
}
.view-cart-content::-webkit-scrollbar {
  width: 8px;
}
.view-cart-content::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 20px;
}
.view-cart-list {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.view-cart-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  font-size: 0.9rem;
}

.view-cart-head > span {
  color: #f00;
  font-size: 1.2rem;
}
.view-cart-head > span >i{
  cursor: pointer;
}

.view-cart-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.view-cart-quantity {
  display: flex;
  border: #03aa1e solid 1px;
  border-radius: 5px;
}
.view-cart-quantity > div {
  background-color: #03aa1e;
  border-radius: 2px;
  color: #fff;
  padding: 0 8px;
  cursor: pointer;
  font-size: 1.2rem;
}

.view-cart-quantity-disable{
  cursor: no-drop !important;
  opacity: .5;
}
.view-cart-quantity > span {
  min-width: 40px;
  text-align: center;
  align-content: center;
}

.view-cart-price {
  font-size: 1.1rem;
  font-weight: 500;
}

.view-cart-end {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.view-cart-total {
  background-color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}
.view-cart-total > div {
  font-weight: 600;
  font-size: 1.2rem;
}

.view-cart-button-disable,
.view-cart-button {
    padding: 8px;
  background-color: #03aa1e;
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.view-cart-button-disable{
    cursor: no-drop;
    opacity: .5;
}

.view-cart-limit{
    padding: 10px 10px 10px;
}
.view-cart-limit-head{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.view-cart-limit-bar{
    background-color: #cccccc;
    border-radius: 10px;
}
.view-cart-limit-bar>div{
    background-color: #03aa1e;
    height: 10px;
    border-radius: 10px;
}


@media only screen and (max-width: 1000px) {
  .view-cart-content {
    padding: 0 10px 200px;
  }
}
