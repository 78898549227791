.admin-login{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.admin-login-logo>img{
  width: 250px;
}
.admin-login-heading-1{
  font-size: 2rem;
  font-weight: 700;
  margin-top: 30px;
}
.admin-login-heading-2{
  font-size: 1.2rem;
  font-weight: 600;
}
.admin-login-form>form{
  display: grid;
  gap: 30px;
  padding: 30px;
}
.admin-login-input{
  align-items: center;
  box-shadow: 0 5px 15px #00000059;
  display: flex;
  
  border-radius: 50px;
  height: 35px;
  width: 300px;
  
}
.admin-login-input>span{
  
  background-color: #fff;
  border-radius: 50%;
  font-size: 1.2rem;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 60px;
  
  align-items: center;
  box-shadow: 0 5px 15px #00000059;
}
.admin-login-input>input{
  background: #0000;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  height: 100%;
  padding-left: 10px;
  width: 100%;
}
.admin-login-form>form button{
  
  background-color: #000;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px auto;
  padding: 5px;
  width: 150px;
}
