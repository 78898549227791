.confirm-booking{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(147, 147, 147, 0.614);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}
.confirm-booking>div{
    background-color: white;
    border-radius: 20px;
    margin: 20px;
}
.confirm-booking-close-btn{
    text-align: end;
    margin-top: -15px;
    margin-right: -10px;
}
.confirm-booking-close-btn i{
    font-size: 2.5rem;
    color: #d3af37;
    background-color: black;
    border-radius: 50%;
}

.confirm-booking-heading{
    text-align: center;    
    margin-top: -10px;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 20px;
}

.confirm-booking-form>form{
    width: 400px;
    display: grid;
    gap: 20px;
    padding: 20px;
}
.confirm-booking-form>form>div{
    display: grid;
}

.confirm-booking-form>form label{
    font-size: 1.1rem;
    font-weight: 500;
}
.confirm-booking-form>form textarea,
.confirm-booking-form>form input{
    border: solid 1px rgb(213, 213, 213);
    font-size: 1rem;
    padding: 5px;
}
.confirm-booking-form>form>button{
    background-color: #d3af37;
    color: white;
    border: none;
    width: 200px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 10px;
    padding: 5px 10px;
    
}
.confirm-booking-form>form>button:hover{
    background-color: #9d7b0b;
}


@media only screen and (max-width: 600px) {
    
.confirm-booking-form>form{
    width: 320px;
}
.confirm-booking-form>form textarea,
.confirm-booking-form>form input{
    width: 270px;
}
}


