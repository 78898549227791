.landing-page {
  position: relative;
}
.landing-page .landing-page-navbar {
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  z-index: 2;
}

.landing-page .home-package-content {
  padding: 60px 20px 20px;
}
.landing-page .home-container-6-content-cols {
  padding: 30px 60px 0;
}

.landing-page .home-container-6 {
  padding-bottom: 60px;
}

/* navbar ====================================================================================================== */

.landing-nav-button {
  background-color: #000;
  color: #fff !important;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 10px;
}

/* header ====================================================================================================== */

.landing-header {
  background-image: url(../../media/images/landing-page-image-5.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* animation: changeBackground 10s infinite alternate; */
}

.landing-header-main {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 40px;
  padding: 40px 60px;
  align-items: center;
  max-width: 1400px;
  margin: auto;
}
.landing-page-ncr
.landing-header-main{
display: flex;
justify-content: center;
padding: 150px 40px;
}
.landing-header-content {
  color: #fff;
  text-align: center;
}

.landing-header-heading-1 {
  font-size: 2.8rem;
  text-transform: uppercase;
  font-family: "Times New Roman", Times, serif;
}
.landing-header-heading-1 > div {
  color: #d3af37;
  display: inline;
}
.landing-header-heading-2 {
  font-size: 1.2rem;
  margin-top: 30px;
  text-transform: uppercase;
}

.landing-header-form > form {
  display: grid;
  background-color: #fff;
  background-color: #fffbee;
  padding: 40px;
  border-radius: 30px;
  gap: 20px;
  text-align: center;
  /* animation: shake 0.5s ease-in-out ; */
}
.landing-header-form-animate {
  animation: shake 0.8s ease-in-out;
}

.landing-header-form-heading-1 {
  font-size: 1.7rem;
  font-weight: 600;
}

.landing-header-form-heading-2 {
  color: #ff0000;
  margin-top: -20px;
}

.landing-header-form > form > input {
  padding: 12px 10px;
  font-size: 0.9rem;
  border-radius: 20px;
  border: 1px solid #80808047;
}
.landing-header-form > form > a {
  color: #000;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  margin: auto;
}

.landing-header-form > form > button {
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-size: 1.1rem;
  border-radius: 20px;
  border: none;
}

/*  ====================================================================================================== */
.landing-page .home-container-6-head {
  /* display: none; */
  justify-content: center;
}

.landing-page-button-mobile-yellow,
.landing-page-button-web-yellow {
  display: none;
}

.landing-page .landing-page-button-mobile-yellow,
.landing-page .landing-page-button-web-yellow {
  width: max-content;
  margin: auto;
  padding: 15px 50px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
  background-color: #d3af37;
  color: #fff;
  margin-top: 30px;
  display: block;
}
.landing-page .landing-page-button-mobile-yellow {
  display: none;
}
.home-container-6-head-col-2 .landing-page-button-mobile-yellow,
.home-container-6-head-col-2 .landing-page-button-web-yellow {
  margin-top: 0;
}
/* .landing-mobile-target{
    display: none;
    } */
.landing-page-button-web-yellow:hover,
.landing-page-button-mobile-yellow:hover {
  color: black !important;
  background-color: #d3af37 !important;
}

.landing-page-button-black {
  background-color: #000 !important;
}

/* @keyframes changeBackground {
    16% {
      background-image: url(../../media/images/);
    }
    33% {
        background-image: url(../../media/images/);
    }
    50% {
        background-image: url(../../media/images/);
    }
    66% {
        background-image: url(../../media/images/);
    }
    82% {
        background-image: url(../../media/images/);
    }
    100% {
      background-image: url(../../media/images/);
    }
  } */

/*  ====================================================================================================== */

.landing-page-web-get,
.landing-page-mobile-get {
  background-color: #dbb127;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px 15px;
  height: max-content;
  text-decoration: none;
}
.home-package-list .landing-page-mobile-get {
  width: max-content;
  margin: auto;
}

.landing-page-mobile-get {
  display: none;
}

/*  ====================================================================================================== */
/* LandingVideoComponent ====================================================================================================== */
/*  ====================================================================================================== */

.landing-video-main {
  max-width: 1200px;
  margin: 10px auto 50px;
  text-align: center;
  padding: 20px;
}

.landing-video-heading {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  color: #d3af37;
  font-size: 2.2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.landing-video-content {
  /* background-color: #000; */
  max-width: 800px;
  margin: auto;
  padding: 20px 0px 0;
}

.landing-video-content iframe {
  width: 100%;
  height: 400px;
  border-radius: 15px;
}

/*  ====================================================================================================== */
/*  ====================================================================================================== */
.landing-price-list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

}

.landing-price-list-col {
  border: solid 1px rgb(211, 211, 211);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  /* width: 100%; */
  /* margin: 20px auto; */
}

.landing-price-list-col-highlight{
  border: solid 1px #dbb127;
  background: #dbb12731;
  
}


/*  ====================================================================================================== */
/*  ====================================================================================================== */

.price-page-content-col{
  position: relative;
}

.landing-list-price-filter{
  /* position: sticky; */
  top: 110px;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  padding-top: 20px;
  margin-bottom: 30px;
  border-bottom: 5px solid #000;
  background-color: #fff;
}

.landing-list-price-filter>div{
  text-align: center;
  width: 100%;
  background: #ddd;
  padding: 8px 5px ;
  cursor: pointer;
  border-radius: 15px 15px 0 0;
  border: #0003 1px solid;
}

.landing-list-price-filter-option-active{
  background: #000 !important;
  color: white;
}



/*  ====================================================================================================== */
/*  ====================================================================================================== */
/*  ====================================================================================================== */

@media only screen and (max-width: 1000px) {
  .landing-page .landing-page-button-web-yellow {
    display: none;
  }

  .landing-page .landing-page-button-mobile-yellow {
    display: block;
    font-size: 1.1rem;
    padding: 11px 20px;
  }

  .landing-page-navbar .navbar {
    padding: 5px 8px;
  }
  /* header ====================================================================================================== */

  .landing-header {
    background: #fff;
    /* padding: 0 20px; */
    padding: 30px 20px;
    background-image: none;
    animation: none;
  }

  .landing-header-main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    padding: 0px;
    border-radius: 30px 30px 0 0;
    border-radius: 30px 30px 0 0;
  }

  
.landing-page-ncr
.landing-header-main{
  padding: 0;
}

  .landing-header-content {
    background: url(../../media/images/landing-page-image-5.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 10px;
    border-radius: 30px 30px 0 0;
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border-radius: 20px 20px 0 0; */
  }

  .landing-header-heading-1 {
    font-size: 1.6rem;
  }

  .landing-header-heading-2 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .landing-header-form > form {
    padding: 30px 20px 50px;
    border-radius: 0px;
    gap: 15px;
    border-radius: 0 0 30px 30px;
  }

  .landing-header-form-heading-1 {
    font-size: 1.2rem;
  }

  .landing-header-form > form > input {
    padding: 12px 10px;
    font-size: 0.9rem;
    border-radius: 20px;
    border: 1px solid #80808047;
  }

  .landing-header-form > form > a {
    color: #000;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    font-size: 1rem;
    margin: a;
  }

  .landing-header-form > form > button {
    background-color: #000;
    color: #fff;
    padding: 10px;
    font-size: 1.1rem;
    border-radius: 20px;
    border: none;
  }

  .landing-page .location-col {
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .landing-page .location-list-icon > img {
    width: 80px;
    height: 80px;
  }
  .landing-page .location-list-text {
    font-size: 0.9rem;
  }

  /*  ====================================================================================================== */
  /*  ====================================================================================================== */
  /*  ====================================================================================================== */

  .landing-page .faq .landing-page-button-mobile-yellow {
    margin: 10px auto 10px;
  }

  .landing-page .home-container-6-content-cols {
    padding: 30px 20px 10px;
  }

  .landing-nav-col {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .landing-nav-col a {
    font-size: 0.9rem;
    color: #000;
    text-decoration: none;
    font-family: serif;
    font-family: poppins;
    font-weight: 500;
  }
  .landing-nav-col a i {
    font-size: 0.9rem;
  }

  .landing-nav-col i {
    font-size: 1.3rem;
  }

  /* =========================================================================================== */

  .landing-page-web-get {
    display: none;
  }
  .landing-page-mobile-get {
    display: block;
  }

  /*  ====================================================================================================== */
  /* LandingVideoComponent ====================================================================================================== */
  /*  ====================================================================================================== */

  .landing-video-main {
    margin: 10px auto;
  }

  .landing-video-heading {
    font-size: 1.5rem;
  }

  .landing-video-content iframe {
    height: 300px;
  }

  /*  ====================================================================================================== */
/*  ====================================================================================================== */
.landing-price-list{
  grid-template-columns: 1fr;
}

/*  ====================================================================================================== */
/*  ====================================================================================================== */

.landing-list-price-filter{
  top: 80px;
}

.landing-list-price-filter>div{
  font-size: .9rem;
  padding: 5px 5px ;
  display: grid;
}
  
/* .landing-list-price-filter>div>span{
  display: none;
} */

/*  ====================================================================================================== */
/*  ====================================================================================================== */
  
}
