.update-form-main {
  background-color: rgba(0, 0, 0, 0.592);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.update-form-content {
  background-color: rgb(0, 0, 0);
  background-color: white;
  color: black;
  border-radius: 20px;
}
.update-form-close {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-bottom: -10px;
  margin-right: -10px;
}
.update-form-close > span {
  /* padding: 10px; */
  background-color: black;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: solid 1px;
}

.update-form-head{
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center ;
  /* background-color: rgb(255, 0, 0); */
  color: white;
  padding: 5px;
  margin-top: -21px;
  border-radius: 20px 20px 0 0;
  border: solid;
}

.update-form-data {
  padding: 10px 20px 20px;
  display: grid;
  gap: 10px;
}

.update-form-data-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}

.update-form-data-heading{
    font-weight: 700;
}

.update-form-content form {
  display: grid;
  gap: 10px;
}

.update-form-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.update-form-lable{
    font-weight: 700;
}
.update-form-input {
  display: flex;
  gap: 30px;
}
.update-form-input>div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.update-form-input div input{
   height: 25px;
   width:  25px;
}

.update-form-button{
    width: 150px;
    text-align: center;
    /* background-color: rgb(255, 0, 0); */
    color: white;
    padding: 5px 30px;
    border-radius: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
} 