.price-page {
  background-color: white;
  padding: 40px;
}
.price-page > div {
  max-width: 1170px;
  margin: auto;
}

.price-page-heading {
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
  color: #e4bc36;
  text-align: center;
}

.price-page-content {
  display: grid;
  gap: 50px;
  padding: 10px 0;
}

.price-page-content-col-heading {
  text-align: center;
  border-bottom: solid 1px rgb(211, 211, 211);
  padding: 10px;
  /* font-family: "Times New Roman", Times, serif; */
  font-weight: 600;
  /* color: rgb(72, 72, 72); */
  font-size: 1.3rem;
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
  z-index: 0;
  background-color: white;
}

.price-page-content-col-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.price-page-content-col-list-col {
  border: solid 1px rgb(211, 211, 211);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.price-page-content-col-list-heading{
  text-transform: uppercase;
}

.price-page-content-col-list-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(95, 95, 95);
}

.price-page-content-col-list-button > span {
  cursor: pointer;
  background-color: #dbb127;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
}
.price-page-content-col-list-button > span:hover{
  background-color: #ffdb63;
  color: black;
}
.price-page-content-col-list-button > div{
  cursor: pointer;
  background-color: #f00;
  background-color: #06da45;
  color: white;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  height: max-content;
  width: max-content;
  margin: auto;
}
.price-page-content-col-list-button > div:hover{
  background-color: #06a837;

}

@media only screen and (max-width: 600px) {
  .price-page {
    background-color: white;
    padding: 30px 10px;
  }

  .price-page-heading {
    font-size: 1.5rem;
  }

  .price-page-content-col-heading {
    padding: 15px;
    font-size: 1.1rem;
  }

  .price-page-content-col-list {
    grid-template-columns: 1fr ;
    gap: 10px;
  }

  .price-page-content-col-list-col {
    border: solid 1px rgb(211, 211, 211);
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
  }

}
