
/* ------------------- home-container-6 --------------- */

.home-container-6 {
  text-align: center;
  padding: 40px 0;
  font-family: "poppins";
}

.home-container-6-heading {
  padding: 10px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  color: #d3af37;
  font-size: 2.2rem;
  font-weight: 600;
  /* text-transform: uppercase; */
}

.home-container-6-head-col-1-heading-1 {
  font-size: 18px;
  font-size: 1.4rem;
  font-weight: 600;
}

.home-container-6-head {
  max-width: 1170px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
}

.home-container-6-head-col-1-heading-2 {
  font-size: 1.1rem;
}
.home-container-6-head-col-1-heading-2 > i {
  color: #f9cc18;
}

.home-container-6-head-col-2 {
  padding: 20px;
}

.home-container-6-head-col-2 > a {
  text-decoration: none;
  background-color: #d3af37;
  color: black;
  color: white;
  font-size: 17px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px;
}
.home-container-6-head-col-2 > a:hover{
  border: #d3af37 solid;
  background-color: white;
  color: black;
}

.home-container-6-content-cols {
  display: flex;
  overflow: scroll;
  gap: 20px;
  padding: 30px 60px;
  max-width: 2200px;
  margin: auto;
}
.home-container-6-content-cols::-webkit-scrollbar {
  display: none;
}

.home-container-6-content-col-1 {
  /* width: 80%; */
  min-width: 380px;
  /* margin: 15px auto; */
  border: solid 2px gainsboro;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home-container-6-content-cos-1-google img {
  width: 30px;
}
.home-container-6-content-cos-1-text a{
  color: #d3af37;
}
.home-container-6-content-cos-1-text a:hover{
  color: #a58418;
}

.home-container-6-content-cos-1-stars {
  color: #f9cc18;
  display: flex;
  justify-content: center;
  gap: 12px;
  font-size: 23px;
  padding: 5px;
  /* z-index: -1; */
}

.home-container-6-content-cos-1-stars i {
  animation: hithere 1.8s ease infinite;
  z-index: -1;
}

@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.3); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}

.home-container-6-content-cos-1-name {
  font-size: 18px;
  font-weight: 600;
}

.home-container-6-content-cos-1-date {
  color: gray;
}


@media only screen and (max-width: 600px) {

.home-container-6-head {
  flex-direction: column;
}
  
.home-container-6-heading {
  font-size: 1.9rem;
}

.home-container-6-head {
  margin: 0px;
}

.home-container-6-head-col-1-heading-1 {
  font-size: 1.2rem;
}


.home-container-6-head-col-2 > a {
  background-color: #d3af37;
  font-size: 1rem;
}

.home-container-6-content-cols {
  padding: 10px 30px;
}

.home-container-6-content-col-1 {
  padding: 10px;
  min-width: 280px;
}

  
}


