



.gms-price-list-main{
    max-width: 1300px;
    padding: 40px;
    margin: auto;
    /* padding: 150px 40px; */
    display: grid;
    grid-template-columns: 1fr 3fr;
    position: relative;
}

.gms-price-list-menu{
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: max-content;
    padding: 10px 0;
    max-height: 500px;
    overflow: auto;
    position: sticky;
    top: 70px;
}
.gms-price-list-menu::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}
.gms-price-list-menu::-webkit-scrollbar-thumb{
    cursor: grab;
    background-color: var(--main);
    border-radius: 10px;
}


.gms-price-list-menu-label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    border-bottom: 1px #e0e0e0 solid;
    padding: 10px 15px;
    cursor: pointer;
}
.gms-price-list-menu-label:hover{
    color: var(--main);
    }
.gms-price-list-menu-label>i{
    display: none;
}
.gms-price-list-menu-label-active{
    /* border-bottom: 1px #e0e0e0 solid; */
    border-color: var(--main);
    color: var(--main);
    font-weight: 600;
}
.gms-price-list-menu-label-active>i{
    display: block;
}
.gms-price-list-menu-label>div{
    display: flex;align-items: center;
    gap: 10px;
}
.gms-price-list-menu-label>div>img{
    width: 30px;
}


.gms-price-list-content-heading{
    font-size: 1.7rem;
    font-weight: 600;
}

.gms-price-list-content-data{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}


.gms-price-list-content-col{
    position: relative;
    border: solid 1px #dedede;
    padding: 7px;
}    

.gms-price-list-content-col-img{
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 100%;
}
.gms-price-list-content-col-img>img{
    width: 100%;
    height: 100%;
    display: flex;
}

.gms-price-list-content-col-img::after{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    z-index: 0;
    content: "";
    background: linear-gradient(to bottom, rgba(246, 246, 246, 0) 0, rgba(19, 19, 19, .72) 82%, rgba(0, 0, 0, .72) 89%);
}

.gms-price-list-content-col-min{
    position: absolute;
    width: max-content;
    width: 60px;
    top: 0;
    left: 0;
}
.gms-price-list-content-col-min-content{
    text-align: center;
    font-size: .9rem;
    color: #fff;
    background: var(--main);
    padding: 10px 5px;
}
.gms-price-list-content-col-min-shape{
    height: 10px;
    
    border-right: 30px solid var(--main);
    border-left:   30px solid var(--main);
    border-bottom: 15px solid transparent;
}

    
.gms-price-list-content-col-section-2,
.gms-price-list-content-col-section-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    z-index: 1;
}

.gms-price-list-content-col-price{
    background-color: var(--main);
    color: #fff;
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;
    font-size: .8rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-left: 40px;
}
.gms-price-list-content-col-price>div{
    font-size: 1.1rem;
    font-weight: 600;
}

.gms-price-list-content-col-price>span{
    background-color: #fff;
    color: var(--main);
    padding: 0 5px;
    border-radius: 5px;
    font-weight: 600;
}
.gms-price-list-content-col-button{
    background-color: var(--button);
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px 0 0 5px;
    font-size: .9rem;
    position: relative;
    margin-right: -12px;
    min-width: max-content;
}
.gms-price-list-content-col-button::after{
    position: absolute;
    content: "";
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgba(3, 170, 30, .5);
    right: 0;
    top: 100%;
}
.gms-price-list-content-col-button:hover{
    cursor: pointer;
    background-color: var(--main);
}

.gms-price-list-content-col-section-1{
    position: absolute;
    top: 5px;
}
.gms-price-list-content-col-section-2{
    color: #fff;
    align-items: flex-end;
    gap: 15px;
    position: absolute;
    bottom: 20px;
    font-size: 15px;
}


@media only screen and (max-width: 1000px) {

.gms-price-list-main{
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 20px 0;
}

.gms-price-list-menu{
    position: relative;
    top: 0;
    display: flex;
    margin-right: 0;
    padding: 10px 0 5px;
}
.gms-price-list-menu-label{
    align-items: flex-start;
}
.gms-price-list-menu-label>div{
    flex-direction: column;
    text-align: center;
    justify-content: start;
    width: max-content;
}
.gms-price-list-menu-label>div>img{
    width: 50px;
}
.gms-price-list-menu-label>i{
    display: none;
}

.gms-price-list-content-data{
    grid-template-columns: 1fr ;
}

.gms-price-list-content{
    padding: 15px;
}

.gms-price-list-content-col-section-1{
    /* display: none; */
    padding: 7px 10px;
}

.gms-price-list-content-col-min-content{
    font-size: .83rem;
    padding: 5px 1px;
}
    

.gms-price-list-content-col-min{
    width: 44px;
}

.gms-price-list-content-col-min-shape{
    height: 10px;
    border-right:  22px solid var(--main);
    border-left:   22px solid var(--main);
    border-bottom: 10px solid transparent;
}

.gms-price-list-content-col-price{
    margin-left: 26px;
    padding: 3px 5px;
}

.gms-price-list-content-col-price>div{
    font-size: 1rem;
}

.gms-price-list-content-col-price>span{
    font-size: .75rem;
}


.gms-price-list-content-col-button{
    padding: 3px 8px;
}


}

