.why-us>div{
    max-width: 1170px;
    margin: 40px auto 0px;

}

.why-us-heading{
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    color: #d3af37;
    font-size: 2.2rem;
    font-weight: 600;
}
.why-us-icon{
    text-align: center;
    
}
.why-us-icon>img{
    width: 150px;
}

.why-us-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 50px 0;
}

.why-us-content-col>img{
    width: 100%;
}
.why-us-content-col{
    display: grid;
}

.why-us-content-col-list{
    display: flex;
    gap: 20px;
}
.why-us-content-col-list-icon>img{
    width: 60px;
}

.why-us-content-col-list-content-heading{
    font-weight: 600;
    font-size: 1.1rem;
}
.why-us-content-col-list-content-text{
    font-size: .9rem;
}


.why-us-increment{
    background-color: black;
    color: white;
    padding: 50px 0;
}

.why-us-increment>div{
    max-width: 1170px;    
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    gap: 20px;

}

.why-us-increment-col-heading{
    color: #d3af37;
    font-size: 4rem;
}


@media only screen and (max-width: 600px) {
    .why-us>div{
        margin: 30px auto 0px;
    
    }

    
.why-us-icon>img{
    width: 100px;
}
    
.why-us-heading{
    font-size: 1.5rem;
}
.why-us-text{
    display: none;
}
.why-us-content{
    grid-template-columns: 1fr;
    padding: 20px;
}


.why-us-content-col>img{
    width: 70%;
    margin: auto;
}

.why-us-content-col{
    gap: 20px;
}

.why-us-content-col-list{
    gap: 10px;
}


.why-us-content-col-list-icon>img{
    width: 50px;
}


.why-us-increment{
    padding: 20px 10px;
}


.why-us-increment-col-heading{
    color: #d3af37;
    font-size: 2rem;
    font-weight: 500;
}

.why-us-increment-col-text{
    font-size: .8rem;
}

    
}


