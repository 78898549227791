/* HTML: <div class="loader"></div> */
.loader {
  width: 88px;
  height: 12px;
  --_g: no-repeat radial-gradient(farthest-side, #000 94%, #0000);
  background: var(--_g) 25% 0, var(--_g) 75% 0;
  background-size: 12px 12px;
  position: relative;
  animation: l24-0 1s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  height: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  inset: 0;
  margin: auto;
  animation: l24-1 1s cubic-bezier(0.5, 300, 0.5, -300) infinite;
}
@keyframes l24-0 {
  0%,
  24% {
    background-position: 25% 0, 75% 0;
  }
  40% {
    background-position: 25% 0, 85% 0;
  }
  50%,
  72% {
    background-position: 25% 0, 75% 0;
  }
  90% {
    background-position: 15% 0, 75% 0;
  }
  100% {
    background-position: 25% 0, 75% 0;
  }
}
@keyframes l24-1 {
  100% {
    transform: translate(0.1px);
  }
}

/* ---------------------------------------------------------- */
@keyframes flipX {
  0% {
    color: white;
    opacity: 0;
    transform: rotateX(90def);
  }
  25% {
  color: rgb(255, 93, 93);
  opacity: 1;
    transform: rotateX(2turn);
  }
  50% {
  color: rgb(255, 36, 36);
  opacity: 1;
    transform: rotateX(2turn);
  }
  100% {
    color: red;
    opacity: 1;
    transform: rotateX(2turn);
  }
}

/* ---------------------------------------------------------- */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0%,
  10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
  }
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-8deg);
  }
  20% {
    transform: rotateZ(5deg);
  }
  25% {
    transform: rotateZ(-5deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

@keyframes play {
  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 3px #4b4b4b60;
  }
  25% {
    box-shadow: 0 0 0 7px #4b4b4b60, 0 0 0 17px #4b4b4b60;
  }
  25% {
    box-shadow: 0 0 0 12px #4b4b4b60, 0 0 0 25px #4b4b4b60;
  }
}

@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 3px #fd272760;
  }
  25% {
    box-shadow: 0 0 0 7px #ff373797, 0 0 0 17px #fe00009a;
  }
  25% {
    box-shadow: 0 0 0 12px #ff2d2d60, 0 0 0 25px #ff353565;
  }
}

@keyframes pulse-border {
  0% {
    opacity: 0.75;
    padding: 28px;
  }

  75% {
    opacity: 0;
    padding: 40px;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: translate(0) rotate(0deg);
  }
  5% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-3px) rotate(1deg);
  }
  15% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  20% {
    transform: translate(1px, -2px) rotate(1deg);
  }
  25% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  30% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  35% {
    transform: translate(3px, -2px) rotate(-1deg);
  }
  40% {
    transform: translate(-1px, 3px) rotate(1deg);
  }
  45% {
    transform: translate(2px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  55% {
    transform: translate(-3px, 1px) rotate(-1deg);
  }
  60% {
    transform: translate(1px, -2px) rotate(0deg);
  }
  70% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  80% {
    transform: translate(3px, -1px) rotate(0deg);
  }
  90% {
    transform: translate(0) rotate(0deg);
  }
  100% {
    transform: translate(0) rotate(0deg);
  }
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.3);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
