
.nav-top-2{
    background-color: black;
    font-weight: 500;
    text-align: center;
    padding: 5px ;
    display: flex;
    gap: 5px;
    justify-content: center;
    color: #d3af37;
    text-decoration: none;
  }
  .nav-top-2>span{
    color: white;
  }
  .nav-top-2>div{
  animation: blink 4s linear infinite;

}


/* ------------- nav top start ----------------------- */

.nav-top{
    background-color: black;
    color: white;
    padding: 5px;
}
.nav-top>div{
    display: flex;
    justify-content: space-between;
    /* padding: 5px 190px; */
    max-width: 1170px;
    margin: auto;
    font-size: .9rem;
    align-items: center;
    
}

.nav-top-col{
    display: flex;
    gap: 50px;
    align-items: center;
}
.nav-top-col a{
    color: white;
}
.nav-top-col a:hover{
    color: #d3af37;
}


.nav-top-col>div>i{
    color: #d3af37;
}

.nav-top-col-button{
    /* border: #d3af37 solid; */
    color: #d3af37;
    border: solid 1px;
    padding: 8px 15px;
    font-size: .9rem;
}

.nav-top-col-icons{
    display: flex;
    gap: 20px;
    padding-left: 30px;
}

.nav-top-col-icons>a{
    color: white;
}

/* ------------- nav top end ----------------------- */

.navbar-main{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
    background-color: white;
    z-index: 1;
}


.navbar {
      background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding:  1rem 0;
  
    max-width: 1250px;
    margin: auto;
  
  }
  
  .nav-logo img{
    width: 200px;
  }
  
  .nav-btn {
    display: none;
  }
  
  .nav-menu a,
  .nav-menu div {
    color: black;
    font-size: 1rem;
    text-decoration: none;
    /* margin-right: 50px; */
  }
  .nav-menu a:hover,
  .nav-menu div:hover{
    color: #d3af37;
  }
  
  
  /* drop down ----------------------- */
  
  .nav-menu div span:hover {
    cursor: grab;
  }
  
  
  .nav-drop-content {
    display: none;
    position: absolute;
    padding: 10px;
    width: 8rem;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
    background-color: white;
  }
  
  .nav-drop:hover .nav-drop-content {
    display: block;
  }
  
  .nav-d-c-link {
    display: grid;
    gap: 10px;
    margin-right: 0;
  }
  .nav-d-c-link>a{
    margin-right: 0 ;
  }
  
  /* drop down ----------------------- */
  
  .nav-menu {
    display: flex;
    gap: 50px;
    align-items: center;
  }
  
/* ------------- navbar end ----------------------- */

@media only screen and (max-width: 600px) {

.nav-top-2{
  /* display: grid; */
  flex-wrap: wrap;
  gap: 0;
  font-size: .8rem;
}
  
  
    .nav-top{
        display: none;
    }
    .nav-top-col{
        display: grid;
        gap: 0px;
        font-size: .8rem;
    }
    
    .nav-top-col-icons{
        display: none;
    }
    
    .nav-top-col-button{
        padding: 5px 10px;
        font-size: .8rem;
}


/* ----------- navbar ---------------- */

.nav-logo img{
    width: 130px;
    display: flex;
  }
.menu-btn {
    display: block;
    margin-right: 10px;
    border: none;
    font-size: 1.3rem;
    background-color: transparent;
    color: black;
  }

  .navbar {
    padding: 5px;
    height: auto;
  }

  
  .navbar .nav-menu {
    margin: 0px -20px;
    padding: 0;
    position: fixed;
    bottom: 100vh;
    height: 100%;
    width: 102%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #fff5d2;
    transition: .4s;
  }

  .navbar .responsive_nav {
    transform: translateY(100vh);
    /* background-color: black; */
    z-index: 2;
  }

  /* drop down ---------------------- */
  .nav-drop-content {
    width: 300px;
    text-align: start;
    padding: 20px;
    background-color: transparent;
  }


  .nav-close-btn {
    display: block;
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 2rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: black;
  }

}