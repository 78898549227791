.gallery-page{
    background-color: white;
    padding: 40px;
}
.gallery-page>div{
    max-width: 1170px;
    margin: auto;
}

.gallery-page-heading{
    font-size: 2.2rem;
    color: #d3af37;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}
.gallery-page-icon{
    text-align: center;
}
.gallery-page-icon img{
    width: 150px;
    margin-top: -10px;
    margin-bottom: 30px;
}
.gallery-page-images{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
}

.gallery-page-images>img{
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .gallery-page{
        padding: 30px 15px;
    }

    
.gallery-page-heading{
    font-size: 1.5rem;
}

.gallery-page-icon img{
    width: 100px;
    margin-top: -5px;
    margin-bottom: 20px;
}

.gallery-page-images{
    grid-template-columns: 1fr ;
}

    
}
