.booking-form {
    position: fixed;
    top: 0px;
    z-index: 6;
    width: 100%;
    height: 100vh;
    background-color: rgba(154, 154, 154, 0.504);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .booking-form-heading-1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #d3af37;
  }
  
  .booking-form-main {
    /* height: 100%; */
    background-color: white;
    border-radius: 15px;
  }
  .booking-form-close {
    text-align: end;
    margin-bottom: -20px;
    
}
.booking-form-close i {
    padding: 8px;
    font-size: 0.8rem;
    background-color: #d3af37;
    color: black;
    border-radius: 50%;
  }
  .booking-form-content {
    padding: 20px;
    /* margin-top: -40px; */
    
  }
  
  .booking-form-content form {
    display: grid;
    gap: 20px;
    margin-top: 10px;
    /* overflow: auto; */
  }
  .booking-form-content form > div {
    display: flex;
    display: grid;
    /* grid-template-columns: 1fr 3fr; */
    /* gap: 5px; */
  }
  .booking-form-content form label{
    font-weight: 500;
  }
  .booking-form-content form input, 
  .booking-form-content form select, 
  .booking-form-content form textarea{
    border-radius: 10px;
    padding: 5px;
  }
  
  .booking-form-content form button {
    background-color: black;
    background-color: #d3af37;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border: 0;
    border-radius: 5px;
    padding: 5px;
    /* box-shadow: 4px 4px 6px 0 #65656580, -4px -4px 6px 0 #00000080,
      inset 1px 2px 9px 0 rgba(141, 136, 136, 0.73), inset 0px 0px 7px 1px rgba(0, 0, 0, 0.789); */
  }
  