.service-faq {
  background-color: white;
  color: black;
  padding: 40px;
}
.service-faq > div {
  max-width: 1170px;
  margin: auto;
}
.service-faq-heading {
  color: #d3af37;

  text-align: center;
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Times New Roman", Times, serif;
}

.service-faq-content {
  display: grid;
  gap: 30px;
  padding: 30px 0;
}
.service-faq-content-list-ques {
  font-size: 1.2rem;
  font-weight: 500;
}
.service-faq-content-list-ans >span{
    font-weight: 500;
}
@media only screen and (max-width: 600px) {
  .service-faq {
    padding: 30px 15px;
  }
  .service-faq-heading {
   font-size: 1.5rem;
   }

  .service-faq-content {
    gap: 20px;
    padding: 10px 0;
  }
  .service-faq-content-list-ques {
    font-size: 1.05rem;
  }
  .service-faq-content-list-ans {
      font-size: .9rem;
    }
}
