.location{
    text-align: center;
    padding: 50px 0;
    background-image: url(../../media/images/Full-Body-Wax-For-Men.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.location>div{
    max-width: 1170px;
    margin: auto;
}
.location a{
    text-decoration: none;
    color: black;
}
.location a:hover{
    color: #d3af37;
    text-decoration: underline;
}

.location-heading{
    color: #d3af37;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    font-size: 2.2rem;
}

.location-icon>img{
    width: 130px;
}

.location-col{
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.location-list{
    display: flex;
    display: grid;
}
.location-list-icon>img{
    border: #fff0bf solid 1px;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    background-color: white;
}


@media only screen and (max-width: 600px) {

    .location{
padding: 30px 0;
    }

.location-heading{
    font-size: 1.4rem;
    padding: 10px 10px 0;
}
    
.location-icon>img{
    width: 100px;
}
.location-col{
    overflow: auto;
    padding: 10px 30px;
}
    
}

