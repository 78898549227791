.bread-crumb{
    
    background: url(../../media/images/Full-Body-Wax-Male-At-Home.jpg);
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
    
    display: flex;
    flex-direction: column;
    height: 280px;
    text-align: center;
    justify-content: center;
    color: white;
    font-size: 1.2rem;
}

.bread-crumb-heading{
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
}
.bread-crumb-path a{
    color: white;
    text-decoration: none;
}
.bread-crumb-path a:hover{
    color: #d3af37;
}
.bread-crumb-path span{
    color: #d3af37;
    text-transform: capitalize;
}


@media only screen and (max-width: 600px) {
    
    .bread-crumb{
        height: 180px;
        font-size: 1rem;
    }
    
    .bread-crumb-heading{
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
}


