
.gms-price-details{
    background: #000000d3;
    position: fixed;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0;
    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 15px;
}

.gms-price-details-close{
    background: #0001;
    position: absolute;
    min-height: 100px;
    min-width: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.gms-price-details-main{
    max-width: 600px;
}
.gms-price-details-main-close{
    display: flex;
    justify-content: flex-end;
}

.gms-price-details-main-close i{
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    font-size: 2.5rem;
    margin-right:  -15px;
    margin-bottom: -25px;
    /* z-index: 1; */
    cursor: pointer;
}

.gms-price-details-img{
    position: relative;
    margin-bottom: -20px;
    z-index: -1;
}

.gms-price-details-img::after{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: "";
    /* background: linear-gradient(to bottom, rgba(246, 246, 246, 0) 0, rgba(19, 19, 19, .72) 82%, rgba(0, 0, 0, .72) 89%); */
    background: linear-gradient(0deg, rgb(0, 0, 0) 6.85%, rgba(0, 0, 0, 0) 63.86%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.gms-price-details-img img{
    width: 100%;
}

.gms-price-details-heading{
    position: absolute;
    bottom: 20px;
    padding: 20px;
    color: #fff;
    z-index: 1;
}

.gms-price-details-data{
    color: #222;
    background-color:#fff;
    border-radius: 20px 20px 0 0;
    padding: 30px 20px;
}

.gms-price-details-price{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.gms-price-details-price-amount{
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 8px;
}

.gms-price-details-price-amount s{
    color: #444;
    font-size: .95rem;
    font-weight: 400;
}
    
.gms-price-details-price-amount span{
    background: var(--main);
    color: #fff;
    border-radius: 5px;
    font-size: .9rem;
    padding: 1px 10px;
}

.gms-price-details-price-time{
    font-size: .95rem;
}

.gms-price-details-book-button{
    background-color: var(--main);
    text-align: center;
    color: #fff;
    padding: 5px;
    font-weight: 500;
    padding: 5px;
    margin-top: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
}


.gms-price-details-data-heading{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0;
}

.gms-price-details-data-list{
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    background: #fafafa;
    padding: 10px;
    font-size: .9rem;
}

.gms-price-details-data-list-point{
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 5px;
}

.gms-price-details-data-list-point > i{
    color: var(--main);
    margin-top: 4px;
}

.gms-price-details-data-list-heading{
    /* font-size: 1rem; */
    font-weight: 600;
    margin: 10px 0 2px;
}


.gms-price-details-data-brand{
    display: flex;
    background: #e29d3b;
    justify-content: space-between;
    padding: 5px 15px;
    font-size: .9rem;
}

.gms-price-details-data-brand-heading{
    font-weight: 600;

}

.gms-price-details-data-brand-list{
    display: flex;
    gap: 15px;

}


@media only screen and (max-width: 1000px) {
    
.gms-price-details-main{

}


.gms-price-details-main-close i{
    font-size: 2.4rem;
}

.gms-price-details-data{
    padding: 20px 15px;
}
    
.gms-price-details-price-amount{
    font-size: 1.3rem;
}
.gms-price-details-price-time{
    font-size: .9rem;
}
    
}




 